/* eslint-disable sonarjs/cognitive-complexity */
import { FormattedMessage, useIntl } from 'react-intl';
import { SystemLevelBatteryStatuses } from '../../generated/GeneratedApiTypes';
import moment from 'moment';
import { NoDataGraph } from '../batteryOverview/utils/BatteryDataUtils';
import { getDaysInYearsAndMonths } from '../battery/utils/DateUtils';

export const BatteryHistoryGraphTooltip = ({ active, payload }: { active?: boolean; payload?: any }) => {
    const intl = useIntl();

    if (!active) {
        return null;
    }

    if (active && payload && payload.length && payload[0].payload) {
        const {
            state_of_health_percentage,
            state_of_health_status,
            mileage,
            full_charge_energy,
            usage_days,
            discharging_energy_throughput,
            depth_of_discharge_percentage,
            timestamp,
        } = payload[0].payload as SystemLevelBatteryStatuses;
        return (
            <div
                className="border border-width-1 border-color-light bg-white padding-x-10 padding-y-10"
                data-testid="historical-graph-tooltip"
            >
                <div>{moment(timestamp).format('D MMMM YYYY')}</div>
                <hr className="margin-y-5" />
                <div>
                    <FormattedMessage id="batteryService.stateOfHealth" />:
                    {state_of_health_percentage ? (
                        <span className="text-medium padding-left-10">{state_of_health_percentage}% </span>
                    ) : (
                        <NoDataGraph />
                    )}
                    {state_of_health_status ? (
                        <span className="text-medium">
                            ({intl.formatMessage({ id: `batteryService.batteryStatus.${state_of_health_status}` })})
                        </span>
                    ) : (
                        <NoDataGraph />
                    )}
                </div>
                <div className="display-flex">
                    <FormattedMessage id="batteryService.totalMileage" />:
                    <div className="padding-left-10">
                        {mileage ? <span className="text-medium">{mileage} km</span> : <NoDataGraph />}
                    </div>
                </div>
                <div className="display-flex">
                    <FormattedMessage id="batteryService.batteryLifetime" />:
                    <div className="padding-left-10">
                        {usage_days ? (
                            <span className="text-medium">{getDaysInYearsAndMonths(usage_days, intl)}</span>
                        ) : (
                            <NoDataGraph />
                        )}
                    </div>
                </div>
                <div className="display-flex">
                    <FormattedMessage id="batteryService.dischargeEnergyThroughput" />:
                    <div className="padding-left-10">
                        {discharging_energy_throughput ? (
                            <span className="text-medium">{discharging_energy_throughput} kWh</span>
                        ) : (
                            <NoDataGraph />
                        )}
                    </div>
                </div>
                <div className="display-flex">
                    <FormattedMessage id="batteryService.fullChargeEnergy" />:
                    <div className="padding-left-10">
                        {full_charge_energy ? (
                            <span className="text-medium">{full_charge_energy} kWh</span>
                        ) : (
                            <NoDataGraph />
                        )}
                    </div>
                </div>
                <div className="display-flex">
                    <FormattedMessage id="batteryService.depthOfDischarge" />:
                    <div className="padding-left-10">
                        {depth_of_discharge_percentage ? (
                            <span className="text-medium">{depth_of_discharge_percentage}%</span>
                        ) : (
                            <NoDataGraph />
                        )}
                    </div>
                </div>
            </div>
        );
    }
    return null;
};
