/* eslint-disable no-shadow */
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import SortDirection from '@rio-cloud/rio-uikit/SortDirection';
import { FormattedMessage } from 'react-intl';
import { IntlShape } from 'react-intl/lib';
import {
    AssetAndBatteryOverview,
    BatteryOverview,
    Criticality,
    SortDirection as Direction,
    SortBy,
} from '../../../generated/GeneratedApiTypes';
import { getDaysInYearsAndMonths } from '../../battery/utils/DateUtils';
import { isNumeric, roundIfNumeric } from '../../battery/utils/NumberUtils';
import { BatteryData } from '../customer/DiagnosticsTable';

export const OK: string = 'OK';
export const WARNING: string = 'WARNING';
export const CRITICAL: string = 'CRITICAL';
export const NO_DATA: string = 'NO_DATA';
export const LOW_MILEAGE: string = 'LOW_MILEAGE';

export const LoadingScreen = () => (
    <>
        <div data-testid="loading-screen" className="col-xs-12 col-sm-6 col-md-4 display-flex flex-column">
            {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
            <ContentLoader height={35} className={'margin-top-10 margin-bottom-10'} />
            <ContentLoader height={100} width={'100%'} className={'margin-top-10 margin-bottom-10'} />
        </div>
    </>
);

export const getSohStatusCssClass = (value: string | undefined) => {
    switch (value?.toUpperCase() || OK) {
        case WARNING:
            return 'warning';
        case CRITICAL:
            return 'danger';
        default:
            return '';
    }
};

export const getCriticalityFromCFE = (value: string) => {
    let criticality: Criticality = 'OK';
    switch (value?.toLowerCase() || 'OK') {
        case 'danger':
            criticality = 'CRITICAL';
            break;
        case 'warning':
            criticality = 'WARNING';
            break;
        default:
            break;
    }
    return criticality;
};

const getProductName = (value: string) => {
    let name: string = '';
    switch (value.toUpperCase()) {
        case 'SERVICE_CARE_S':
            name = 'S';
            break;
        case 'SERVICE_CARE_M':
            name = 'M';
            break;
        case 'SERVICE_CARE_L':
            name = 'L';
            break;
        default:
            break;
    }

    return name;
};

type RequestBody = BatteryOverview.GetBatteryHealthOverviewWithFilters.RequestBody;
export const createRequestBody = (
    currentPageNumber: number,
    assets: string[],
    criticality: Criticality[],
    sortBy: string,
    sortDir: string,
) => {
    const body: RequestBody = {
        asset_ids: assets.length > 0 ? assets : undefined,
        criticality: criticality.length > 0 && criticality.length < 3 ? criticality : undefined,
        sort_by: sortBy as SortBy,
        sort_direction: sortDir as Direction,
        page_number: currentPageNumber,
    };
    return body;
};

export const getSortDir = (newSortBy: string, sortBy: string, sortDir: string) => {
    if (newSortBy === sortBy) {
        return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
};

const roundAndApplyI18n = (value: number | undefined, unit: string, intl: IntlShape): string | undefined => {
    if (isNumeric(value)) {
        return `${intl.formatNumber(roundIfNumeric(value) as number)} ${unit}`;
    }
    return undefined;
};

export const mapData = (data: AssetAndBatteryOverview, intl: IntlShape): BatteryData => {
    const mileageValue = roundAndApplyI18n(data.battery_data.mileage, 'km', intl);

    const throughputValue = roundAndApplyI18n(data.battery_data.discharging_energy_throughput, 'kWh', intl);

    const sohStatusValue = data.battery_data.state_of_health_status;

    const sohValue = roundAndApplyI18n(data.battery_data.state_of_health_percentage, '%', intl);

    const usageValue = data.battery_data.usage_days
        ? getDaysInYearsAndMonths(data.battery_data.usage_days, intl)
        : undefined;

    const productName = getProductName(data.booked_product);

    return {
        soh_status: sohStatusValue!!,
        asset_name: {
            id: data.asset_id,
            name: data.asset_name,
            type: data.asset_type.toLowerCase(),
            product: productName,
        },
        soh: sohValue,
        mileage: mileageValue,
        usage_time: usageValue,
        disch_energy_thru: throughputValue,
    };
};

type NoDataProps = {
    textSize?: number;
};

export const NoData = ({ textSize = 18 }: NoDataProps) => (
    <span className={`text-size-${textSize} text-color-gray`}>
        <FormattedMessage id="batteryService.noData" />
    </span>
);
export const NoDataGraph = () => <NoData textSize={14} />;

export const CollectingDataBanner = ({ textSize = 18 }: NoDataProps) => (
    <span className={`text-size-${textSize} text-color-gray`}>
        <FormattedMessage id="batteryService.availableAfter20000km" />
    </span>
);
