import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TableViewToggles, { type TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { RootState } from '../../../configuration/setup/store';
import { Criticality } from '../../../generated/GeneratedApiTypes';
import { getCriticalityFromCFE } from '../utils/BatteryDataUtils';

interface BatteryOverviewState {
    selectedFilters: {
        selectedAssets: string[];
        selectedCriticality: Criticality[];
    };
    selectedViewType: TableViewTogglesViewType;
    isDetailOpen: boolean;
    hasReceivedFilters: boolean;
}

const initialState: BatteryOverviewState = {
    selectedFilters: {
        selectedAssets: [],
        selectedCriticality: [],
    },
    selectedViewType: TableViewToggles.VIEW_TYPE_TABLE,
    isDetailOpen: false,
    hasReceivedFilters: false,
};

interface ViewTypeChanged {
    view_type: TableViewTogglesViewType;
}

interface CriticalityChanged {
    selected_criticality: string[];
}

interface AssetSelectionChanged {
    selected_assets: string[];
}

interface CurrentFiltersSelected {
    selected_assets: string[];
    selected_criticality: string[];
}

const batteryOverviewSlice = createSlice({
    name: 'batteryOverview',
    initialState,
    reducers: {
        viewTypeChanged: (state, action: PayloadAction<ViewTypeChanged>) => {
            const { view_type } = action.payload;
            state.selectedViewType = view_type;
        },
        detailClosed: (state) => {
            state.isDetailOpen = false;
        },
        detailOpened: (state) => {
            state.isDetailOpen = true;
        },
        criticalityChanged: (state, action: PayloadAction<CriticalityChanged>) => {
            const { selected_criticality } = action.payload;
            state.selectedFilters.selectedCriticality = selected_criticality.map(getCriticalityFromCFE);
        },
        assetsChanged: (state, action: PayloadAction<AssetSelectionChanged>) => {
            const { selected_assets } = action.payload;
            state.selectedFilters.selectedAssets = selected_assets;
        },
        currentFiltersSelected: (state, action: PayloadAction<CurrentFiltersSelected>) => {
            const { selected_assets, selected_criticality } = action.payload;
            state.selectedFilters.selectedAssets = selected_assets;
            state.selectedFilters.selectedCriticality = selected_criticality.map(getCriticalityFromCFE);
            state.hasReceivedFilters = true;
        },
    },
});

// Actions
export const {
    viewTypeChanged,
    detailClosed,
    detailOpened,
    criticalityChanged,
    assetsChanged,
    currentFiltersSelected,
} = batteryOverviewSlice.actions;

// Selectors
export const getViewType = (state: RootState) => state.batteryOverview.selectedViewType;
export const isDetailOpen = (state: RootState) => state.batteryOverview.isDetailOpen;
export const getFilters = (state: RootState) => state.batteryOverview.selectedFilters;
export const hasReceivedFilters = (state: RootState) => state.batteryOverview.hasReceivedFilters;

export default batteryOverviewSlice.reducer;
