import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import StatusCode from 'http-status-codes';
import { FormattedMessage } from 'react-intl';

export type InternalErrorStateProps = {
    headlineI18n?: string;
    messageI18n?: string;
    errorCode: number;
    reloadTriggerFunction?: () => void;
    additionalClassName?: string;
    innerClassName?: string;
    fullWidth?: boolean;
    isCustomerFrontend?: boolean;
};

const InternalErrorState = ({
    errorCode,
    reloadTriggerFunction,
    additionalClassName,
    innerClassName,
    fullWidth,
    isCustomerFrontend = false,
}: InternalErrorStateProps) => {
    if (errorCode === StatusCode.NOT_FOUND) {
        if (isCustomerFrontend) {
            return (
                <div>
                    <div className="margin-bottom-15">
                        <FormattedMessage id="batteryService.noDataAvailable" />
                    </div>
                </div>
            );
        } else {
            return <NotFoundState message={<FormattedMessage id="batteryService.error.noInformationAvailable" />} />;
        }
    }

    return (
        <ErrorState
            icon={''}
            headline={<FormattedMessage id="batteryService.error.emptyState.headline" />}
            message={<FormattedMessage id="batteryService.error.emptyState.message" />}
            buttons={[
                {
                    text: <FormattedMessage id="batteryService.error.emptyState.button" />,
                    onClick: reloadTriggerFunction,
                },
            ]}
            // className should keep the original values plus the news values passed
            outerClassName={`display-flex justify-content-center max-width-100pct ${additionalClassName}`}
            innerClassName={innerClassName}
            fullWidth={fullWidth}
        />
    );
};

export default InternalErrorState;
