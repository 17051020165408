import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import classNames from 'classnames';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useMatch } from 'react-router';
import { getItemToLocalStorage, saveItemToLocalStorage } from '../../../config';
import InternalErrorState from '../../app/InternalErrorState';
import {
    CollectingDataBanner,
    CRITICAL,
    LOW_MILEAGE,
    NO_DATA,
    NoData,
    OK,
    WARNING,
} from '../../batteryOverview/utils/BatteryDataUtils';
import { getDaysInYearsAndMonths } from '../utils/DateUtils';
import SohBanner from '../SohBanner';
import { useFetchBatteryLevelsQuery } from '../api/batterySlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ExternalBatteryStateOfChargeStatus } from '../../../generated/GeneratedApiTypes';
import BatteryHistoryGraph from '../../batteryHistoryGraph/BatteryHistoryGraph';
import { FT_HISTORY_GRAPH, isFeatureEnabled } from '../../../configuration/featureToggle/featureToggleSlice';
import { useAppSelector } from '../../../configuration/setup/hooks';
import BarChart from '../BarChart';
import PanelBody from '../PanelBody';

const LoadingScreen = () => {
    return (
        <>
            <div data-testid="loading-screen" className="col-xs-12 col-sm-6 col-md-4 display-flex flex-column">
                {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
                <ContentLoader height={35} className={'margin-top-10 margin-bottom-10'} />
                <ContentLoader height={100} width={'100%'} className={'margin-top-10 margin-bottom-10'} />
            </div>
        </>
    );
};

const wrapperClassNames = classNames('display-flex flex-column align-items-start margin-5');

const spacerClassNames = classNames(
    'margin-0 margin-bottom-5 border border-color-blue ' + 'border-top-only border-width-5 width-100pct',
);

const SohTooltipBody = ({ status }: { status: string }) => {
    const content = [
        <Fragment key={1}>
            <h6>
                <FormattedMessage id="batteryService.batteryStateOfHealth.tooltip.title" />
            </h6>
            <hr className={spacerClassNames} />
            <span>
                <FormattedMessage id={`batteryService.batteryStateOfHealth.tooltip.${status.toLowerCase()}`} />
            </span>
        </Fragment>,
    ];

    if ([WARNING, CRITICAL].includes(status)) {
        content.push(
            <Fragment key={2}>
                <ul>
                    <li>
                        <FormattedMessage id="batteryService.batteryStateOfHealth.tooltip.body" />
                    </li>
                    <li>
                        <FormattedMessage id="batteryService.batteryStateOfHealth.tooltip.body2" />
                    </li>
                </ul>
                <hr className={'margin-0 margin-bottom-5'} />
                <span className={'text-bold'}>
                    <FormattedMessage id="batteryService.batteryStateOfHealth.tooltip.recommendation" />
                </span>
                <span>
                    <FormattedMessage id="batteryService.batteryStateOfHealth.tooltip.recommendation2" />
                </span>
                <span>
                    <FormattedMessage id="batteryService.batteryStateOfHealth.tooltip.recommendation3" />
                </span>
            </Fragment>,
        );
    }
    return (
        <div className={wrapperClassNames} data-testid="tooltip-overlay">
            {content}
        </div>
    );
};

const EnergyThroughoutTooltipBody = () => {
    return (
        <div className={wrapperClassNames} data-testid="tooltip-overlay">
            <h6>
                <FormattedMessage id="batteryService.dischargeEnergyThroughput" />
            </h6>
            <hr className={spacerClassNames} />
            <span>
                <FormattedMessage id="batteryService.dischargeEnergyThroughput.tooltip" />
            </span>
        </div>
    );
};

export const SohToolTipBodyMemoized = memo(SohTooltipBody);
export const EnergyThroughputTooltipBodyMemoized = memo(EnergyThroughoutTooltipBody);

const VIN_SEARCH_TOKEN_PARAM_NAME = 'vin_search_token';
const VIN_SEARCH_LOCAL_STORAGE_KEY_PREFIX = 'vin-search';
const KM = ' km';
const KWH = ' kWh';
const NO_VALUE = '-';

export const handleVinSearchToken = (assetId: string): string => {
    const vinSearchToken = new URLSearchParams(window.location.search).get(VIN_SEARCH_TOKEN_PARAM_NAME);

    if (vinSearchToken == null) {
        return getItemToLocalStorage(`${VIN_SEARCH_LOCAL_STORAGE_KEY_PREFIX}:${assetId}`) || '';
    }

    saveItemToLocalStorage(`${VIN_SEARCH_LOCAL_STORAGE_KEY_PREFIX}:${assetId}`, vinSearchToken);
    return vinSearchToken;
};

// eslint-disable-next-line max-len
const renderBatteryStatus = (
    status: ExternalBatteryStateOfChargeStatus | undefined,
    remaining_range: number | undefined,
) => {
    let glyphClassNames;
    switch (status) {
        case 'CRITICAL':
            {
                // eslint-disable-next-line max-len
                glyphClassNames = classNames(
                    'rioglyph rioglyph-battery-level-empty text-color-danger text-size-20 margin-right-10 rotate-90',
                );
            }
            break;
        case 'WARNING':
            {
                // eslint-disable-next-line max-len
                glyphClassNames = classNames(
                    'rioglyph rioglyph-battery-level-low text-color-warning text-size-20 margin-right-10 rotate-90',
                );
            }
            break;
        default: {
            glyphClassNames = classNames('rioglyph rioglyph-battery-level-full text-size-20 margin-right-10 rotate-90');
        }
    }

    return (
        <>
            <div className={'display-flex align-items-center margin-top-5'}>
                <div className="webfont-preview display-flex align-items-center">
                    <span data-testid="battery-glyph" className={glyphClassNames} />
                </div>
                <span>
                    {remaining_range}
                    {'%'}
                </span>
            </div>
        </>
    );
};

const panelClassNames = classNames('panel panel-default margin-bottom-0 width-100pct');
const panelWithoutSohStatusClassNames = classNames('panel panel-default margin-bottom-0 width-100pct');
const displayFlex = classNames('display-flex');
const h5ClassName = classNames('margin-bottom-5 margin-top-0');
const marginTop20 = classNames('margin-top-20');
const marginTop5 = classNames('margin-top-5');
const textSize18 = classNames('text-size-18');
const textSize14 = classNames('text-size-14');

const BatteryOverview = () => {
    const intl = useIntl();
    const assetId = useMatch('/:assetId')?.params.assetId;
    const { isLoading, isError, error, data: batteryLevel, refetch } = useFetchBatteryLevelsQuery(assetId!!);
    const shouldShowBatteryHistoryGraph = useAppSelector(isFeatureEnabled(FT_HISTORY_GRAPH));
    const systemStateOfHealthStatus =
        batteryLevel?.health_data?.system.state_of_health_status?.toUpperCase() || NO_DATA;

    const energyThroughputTooltip = useMemo(() => {
        if (isLoading || isError) {
            return <></>;
        }
        return (
            <Tooltip textAlignment={'left'} width={350}>
                <EnergyThroughputTooltipBodyMemoized />
            </Tooltip>
        );
    }, [isLoading, isError]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        return (
            <InternalErrorState
                errorCode={(error as FetchBaseQueryError).status as number}
                reloadTriggerFunction={refetch}
            />
        );
    }

    const dischargingEnergyThroughput = batteryLevel?.health_data?.system.discharging_energy_throughput;
    const mileage = batteryLevel?.health_data?.system.mileage;
    const remainingRange = batteryLevel?.monitoring_data?.remaining_range;

    const renderSoHValue = () => (
        <>
            {systemStateOfHealthStatus === NO_DATA ? (
                <div className={marginTop20}>
                    <NoData />
                </div>
            ) : (
                <PanelBody date={batteryLevel?.health_data?.timestamp} />
            )}
            {[OK, WARNING, CRITICAL].includes(systemStateOfHealthStatus) && (
                <BarChart
                    value={
                        batteryLevel?.health_data?.system.state_of_health_percentage !== undefined
                            ? Math.round(batteryLevel?.health_data?.system.state_of_health_percentage * 10) / 10
                            : undefined
                    }
                    status={systemStateOfHealthStatus}
                />
            )}
            {systemStateOfHealthStatus === LOW_MILEAGE && <CollectingDataBanner />}
        </>
    );

    return (
        <>
            <h4 className={`margin-bottom-${systemStateOfHealthStatus === LOW_MILEAGE ? '15' : '25'}`}>
                <FormattedMessage id="batteryService.batteryOverview.title" />
            </h4>

            {systemStateOfHealthStatus === LOW_MILEAGE && <SohBanner />}

            <div className={'equal-height row'}>
                <div className="col-xs-12 col-sm-6 col-md-6 display-flex">
                    <div data-testid={'component-panel-soh'} className={panelClassNames}>
                        <div className="panel-body">
                            <div className={displayFlex}>
                                <h5 className={h5ClassName}>
                                    <FormattedMessage id="batteryService.batteryStateOfHealth.title" />
                                </h5>
                            </div>
                            {renderSoHValue()}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 display-flex">
                    <div data-testid={'component-panel-soc'} className={panelClassNames}>
                        <div className="panel-body">
                            <div className={displayFlex}>
                                <h5 className={h5ClassName}>
                                    <FormattedMessage id="batteryService.stateOfCharge" />
                                </h5>
                            </div>
                            <div className={marginTop5}>
                                {batteryLevel?.monitoring_data == null ? (
                                    <div className={marginTop20}>
                                        <NoData />
                                    </div>
                                ) : (
                                    <>
                                        <span className={textSize14}>
                                            {remainingRange !== undefined ? (
                                                <>
                                                    <FormattedMessage id="batteryService.remainingRange" />{' '}
                                                    <FormattedNumber value={remainingRange} />
                                                    {KM}
                                                </>
                                            ) : (
                                                NO_VALUE
                                            )}
                                        </span>
                                        <div>
                                            {renderBatteryStatus(
                                                batteryLevel?.monitoring_data?.status,
                                                batteryLevel?.monitoring_data?.state_of_charge,
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 display-flex margin-top-5">
                    <div data-testid={'component-panel-years-of-usage'} className={panelWithoutSohStatusClassNames}>
                        <div className="panel-body">
                            <div className={displayFlex}>
                                <h5 className={h5ClassName}>
                                    <FormattedMessage id="batteryService.batteryLifetime" />
                                </h5>
                            </div>
                            <div className={marginTop20}>
                                <span className={textSize18}>
                                    {batteryLevel?.health_data?.system.usage_days !== undefined ? (
                                        getDaysInYearsAndMonths(batteryLevel.health_data.system.usage_days, intl)
                                    ) : (
                                        <NoData />
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 display-flex margin-top-5">
                    <div data-testid={'component-panel-energy-throughput'} className={panelWithoutSohStatusClassNames}>
                        <div className="panel-body">
                            <div className={displayFlex}>
                                <h5 className={h5ClassName}>
                                    <FormattedMessage id="batteryService.dischargeEnergyThroughput" />
                                </h5>
                                <span className={'margin-left-5'}>
                                    <OverlayTrigger overlay={energyThroughputTooltip} placement={'right'}>
                                        <span
                                            data-testid="energy-throughput-exclamation-sign"
                                            className="rioglyph rioglyph-exclamation-sign text-color-dark text-size-16
                                        width-auto margin-top-0"
                                        />
                                    </OverlayTrigger>
                                </span>
                            </div>
                            <div className={marginTop20}>
                                <span className={textSize18}>
                                    {dischargingEnergyThroughput !== undefined ? (
                                        <>
                                            <FormattedNumber value={dischargingEnergyThroughput} />
                                            {KWH}
                                        </>
                                    ) : (
                                        <NoData />
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 display-flex margin-top-5">
                    <div data-testid={'component-panel-battery-mileage'} className={panelWithoutSohStatusClassNames}>
                        <div className="panel-body">
                            <div className={displayFlex}>
                                <h5 className={h5ClassName}>
                                    <FormattedMessage id="batteryService.totalMileage" />
                                </h5>
                            </div>
                            <div className={marginTop20}>
                                <span className={textSize18}>
                                    {mileage !== undefined ? (
                                        <>
                                            <FormattedNumber value={mileage} />
                                            {KM}
                                        </>
                                    ) : (
                                        <NoData />
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 display-flex margin-top-5">
                    <div className={panelWithoutSohStatusClassNames}>
                        <div className="panel-body">
                            <div className={displayFlex}>
                                <h5 className={h5ClassName}>
                                    <FormattedMessage id="batteryService.fullChargeEnergy" />
                                </h5>
                            </div>
                            <div className={marginTop20}>
                                <span className={textSize18}>
                                    {batteryLevel?.health_data?.system.full_charge_energy !== undefined ? (
                                        <>
                                            <FormattedNumber
                                                value={batteryLevel.health_data.system.full_charge_energy}
                                            />
                                            {KWH}
                                        </>
                                    ) : (
                                        <NoData />
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {shouldShowBatteryHistoryGraph && <BatteryHistoryGraph />}
        </>
    );
};

export default BatteryOverview;
