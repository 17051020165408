import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';
import App from '../features/app/App';
import { ErrorBoundary } from '../features/app/ErrorBoundary';
import BatteryHistoryGraph from '../features/batteryHistoryGraph/BatteryHistoryGraph';
import AssetBatteryData from '../pages/AssetBatteryData';
import AssetsOverview from '../pages/AssetsOverview';

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path="/overview" errorElement={<ErrorBoundary />} element={<AssetsOverview />} />
            <Route path="/:assetId" errorElement={<ErrorBoundary />} element={<AssetBatteryData />} />
            <Route path="/dialog/:assetId" errorElement={<ErrorBoundary />} element={<BatteryHistoryGraph />} />
        </Route>,
    ),
);
