/* eslint-disable no-shadow */
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import ChartTooltip from '@rio-cloud/rio-uikit/ChartTooltip';
import Legend from '@rio-cloud/rio-uikit/Legend';
import Line from '@rio-cloud/rio-uikit/Line';
import LineChart from '@rio-cloud/rio-uikit/LineChart';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useMatch } from 'react-router';
import { isWorkshopParentFrontend } from '../../config';
import InternalErrorState from '../app/InternalErrorState';
import { useFetchHistoricalBatteryHealthQuery } from '../battery/api/batterySlice';
import { LoadingScreen } from '../batteryOverview/utils/BatteryDataUtils';
import { BatteryHistoryGraphTooltip } from './BatteryHistoryGraphTooltip';

const renderCfeHeader = () => (
    <>
        <div className={'margin-bottom-25'}>
            <span
                className="text-color-black
                                   border border-color-black border-bottom-only border-width-3
                                   padding-x-25 padding-y-10
                                   text-size-14"
            >
                <FormattedMessage id="batteryService.batteryAgingHistory.title" />
            </span>
        </div>
        <div className={'margin-bottom-15'}>
            <span>
                <FormattedMessage id="batteryService.batteryAgingHistory.subTitle.extended" />
            </span>
        </div>
    </>
);

const renderWfeHeader = () => (
    <>
        <h4 className="margin-top-15">
            <FormattedMessage id="batteryService.batteryAgingHistory.title" />
        </h4>
        <span className="margin-bottom-15">
            <FormattedMessage id="batteryService.batteryAgingHistory.subTitle" />
        </span>
    </>
);

const BatteryHistoryGraph = () => {
    const isWorkshopParentFE = isWorkshopParentFrontend();

    const assetId = isWorkshopParentFE
        ? useMatch('/:assetId')?.params.assetId
        : useMatch('/dialog/:assetId')?.params.assetId;
    const {
        isLoading,
        isError,
        error,
        data: historicalBatteryHealth,
        refetch,
    } = useFetchHistoricalBatteryHealthQuery(assetId!!);

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        return (
            <InternalErrorState
                errorCode={(error as FetchBaseQueryError).status as number}
                reloadTriggerFunction={refetch}
            />
        );
    }

    const renderHeader = () => (
        <>
            {isWorkshopParentFE && renderWfeHeader()}
            {!isWorkshopParentFE && renderCfeHeader()}
        </>
    );

    return (
        <>
            {renderHeader()}
            {historicalBatteryHealth && (
                <div className="height-400">
                    <LineChart
                        tooltip={<ChartTooltip content={<BatteryHistoryGraphTooltip />} />}
                        legend={
                            <Legend
                                layout="horizontal"
                                verticalAlign="bottom"
                                align="left"
                                wrapperStyle={{
                                    marginLeft: 60,
                                }}
                                formatter={() => (
                                    <span className="text-color-black">
                                        <FormattedMessage id="batteryService.batteryAgingHistory.legend" />
                                    </span>
                                )}
                            />
                        }
                        margin={{ left: -20, top: 5, right: 5, bottom: 0 }}
                        data={historicalBatteryHealth}
                        dataKey={(entry: { timestamp: string }) => moment(entry.timestamp).format('D MMM')}
                        showYAxis
                        showGrid
                        dataUnit="%"
                        lines={[
                            <Line
                                key="line1"
                                dataKey="state_of_health_percentage"
                                dot={false}
                                activeDot={{ r: 5 }}
                                legendType="square"
                                strokeWidth={3}
                                strokeColor={isWorkshopParentFE ? 'gray-darkest' : 'brand-primary'}
                                type="monotone"
                            />,
                        ]}
                        xAxisOptions={{ interval: 'preserveStartEnd' }}
                        yAxisOptions={{ domain: [0, 100], allowDecimals: true, tickCount: 10 }}
                    />
                </div>
            )}
        </>
    );
};

export default BatteryHistoryGraph;
